import axios from "axios";
import axiosRetry from "axios-retry";
import { encode } from "base-64";
import moment from "moment";

interface PostOptions {
  body: any;
}

interface CPFData {
  cpfTitular: string;
  cpfRespFinanc: string;
}

async function getToken() {
  try {
    let minutesDiff = 0;
    let currentToken = sessionStorage.getItem("sToken")
      ? JSON.parse(sessionStorage.getItem("sToken") ?? "")
      : false;
    if (currentToken) {
      const saveHour = moment(currentToken.created_at);
      const currentHour = moment();
      minutesDiff = currentHour.diff(saveHour, "minutes");
      if (minutesDiff <= 24) {
        return currentToken.token;
      } else {
        currentToken = null;
      }
    }

    if (!currentToken) {
      const authToken = encode(
        `${process.env.REACT_APP_SENSEDIA_CLIENT_ID}:${process.env.REACT_APP_SENSEDIA_CLIENT_SECRET}`
      );
      const data = new URLSearchParams();
      data.append("grant_type", "client_credentials");

      const response = await axios.post(
        `${process.env.REACT_APP_SENSEDIA_API_ACCESS_TOKEN}`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${authToken}`,
            //Cookie: "CookieConsentPolicy=0:1; LSKey-c$CookieConsentPolicy=0:1",
          },
        }
      );
      const currentHour = moment();

      sessionStorage.setItem(
        "sToken",
        JSON.stringify({
          token: response.data.access_token,
          created_at: currentHour.format("YYYY-MM-DD HH:mm:ss"),
        })
      );

      return response.data.access_token;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const instance = axios.create();

// axiosRetry(instance, {
//   retries: 2,
//   retryCondition: (error: any) => {
//     return error.response !== undefined && error.response.status >= 500;
//   },
// });

instance.interceptors.request.use(
  async (config: any) => {
    const token = await getToken();
    const { headers } = await getHeaders();
    config.headers = { ...headers, ...config.headers };
    if (token) {
      config.headers = token
        ? { ...config.headers, Authorization: `Bearer ${token}` }
        : { ...config.headers };
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

const postRequest = async (
  url: string,
  options: PostOptions,
  cpf: CPFData | null = null,
  download: "BOLETO" | null = null,
  isFile = false
) => {
  const { body } = options;

  try {
    const headers = await getHeaders(isFile, cpf, download);
    const response = await instance.post(
      `${process.env.REACT_APP_BFF}${url}`,
      body,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const patchRequest = async (
  url: string,
  options: PostOptions,
  cpf: CPFData | null = null,
  isFile = false
) => {
  const { body } = options;

  try {
    const headers = await getHeaders(isFile, cpf);
    const response = await instance.patch(
      `${process.env.REACT_APP_BFF}${url}`,
      body,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const putRequest = async (
  url: string,
  options: PostOptions,
  cpf: CPFData | null = null,
  isFile = false
) => {
  const { body } = options;

  try {
    const headers = await getHeaders(isFile, cpf);
    const response = await instance.put(
      `${process.env.REACT_APP_BFF}${url}`,
      body,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const getRequest = async (
  url: string,
  cpf: CPFData | null = null,
  isFile = false
) => {
  try {
    const headers = await getHeaders(isFile, cpf);
    const response = await instance.get(
      `${process.env.REACT_APP_BFF}${url}`,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const deleteRequest = async (url: string) => {
  try {
    const headers = await getHeaders();
    const response = await instance.delete(
      `${process.env.REACT_APP_BFF}${url}`,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const getHeaders = async (
  isFile = false,
  cpfData: CPFData | null = null,
  download: "BOLETO" | null = null
) => {
  const token = sessionStorage.getItem("token");

  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      autho_api_aws: `Bearer ${token}`,
      cpf: cpfData ? cpfData.cpfTitular : "",
      cpf_resp_financ: cpfData ? cpfData.cpfRespFinanc : "",
      tipo: download ? download : "",
    },
  };

  return headers;
};

export { postRequest, patchRequest, putRequest, deleteRequest, getRequest };
